// loading
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  z-index: 101;
}
.loading {
  width: 100%;

  // img opacity
  @-moz-keyframes loading-img-opacity {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes loading-img-opacity {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes loading-img-opacity {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes loading-img-opacity {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    // img
    .loading-img {
      width: 224px;
      height: auto;
      -moz-animation: loading-text-opacity 3s linear 0s infinite normal;
      -o-animation: loading-img-opacity 3s linear 0s infinite normal;
      -webkit-animation: loading-img-opacity 3s linear 0s infinite normal;
      animation: loading-img-opacity 3s linear 0s infinite normal;
      opacity: 0;
      img {
        width: 100%;
      }
    }
  }
}
